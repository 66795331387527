import { Environment } from "../../index";

/**
 * All environment variables accessible from JavaScript Side
 */
export const ACTIVE_ENVIRONMENT: Environment = {
  name: "PROD",
  config: {
    apiKey: "AIzaSyAJsyux9JW4iAQ4ZY-KjOC9BIFpgpvwWEg",
    authDomain: "meeter-prod-a9171.firebaseapp.com",
    databaseURL: "https://meeter-prod-a9171-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "meeter-prod-a9171",
    storageBucket: "meeter-prod-a9171.appspot.com",
    messagingSenderId: "283852303648",
    appId: "1:283852303648:web:f89511c1bcd9470c8cbb45",
    measurementId: "G-MHMWRKSEV4"
  },
};
